"use client"; // Error components must be Client Components

import Error500 from "@app/components/common/errors/Error500";
import { ErrorStatus, useErrorContext } from "@app/providers/ErrorProvider/ErrorProvider";
import { useEffect } from "react";
import { useUpdateIntercom } from "./hooks/useUpdateIntercom";

export default function ErrorBoundary({
  error,
  reset,
}: Readonly<{
  error: Error & { digest?: string };
  reset: () => void;
}>) {
  const { reportError } = useErrorContext();

  useEffect(() => {
    reportError({ message: error.message, stack: error.stack, status: ErrorStatus.fatal });
  }, [error, reportError]);

  const { hideIntercom } = useUpdateIntercom();

  hideIntercom();

  return <Error500 source="app/shop/error" reset={reset} />;
}
